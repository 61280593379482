/* eslint-disable */
import css from 'styled-jsx/css';

const styles = css`
  :global(.hafh-logo) {
    width: 64px;
    margin-top: 60px;
  }

  :global(.hero) {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  :global(.return-button) {
    margin-top: 36px;
  }

  .padding {
    padding-bottom: 50px;
  }

  .wrap {
    font-family: system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji';
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .description {
      width: 250px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
`;

export default styles;
